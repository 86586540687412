/* header */
#header {
	//position: relative;
	position: sticky;
	top: 0;
	z-index: 300;
	padding: 5.8rem 0 3.3rem;
	background: $body-bg;
	transition: all 0.3s ease-out;

	@include media-breakpoint-down(md) {
		padding: 5.3rem 0 2.2rem;
	}

	&.position-over {
		position: fixed;
		left: 0;
		right: 0;
	}

	html:not(.bg-header-position-over):not(.push) &.position-over {
		background: none;
	}

	.scrolled-header.scroll-down & {
		transform: translate(0, -100%);
	}

	.container-fluid {
		display: flex;
		align-items: center;
	}

	.logo {
		flex-shrink: 0;
		position: relative;
		z-index: 15;

		a {
			display: block;
			text-decoration: none;
		}

		img {
			display: block;
			width: auto;
			max-height: 5.6rem;
		}
	}

	.nav-bar {
		margin-left: auto;

		@include media-breakpoint-down(md) {
			position: fixed;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: $white;
			overflow-y: auto;
			overflow-x: hidden;
			transition: all 0.3s ease-out;
			opacity: 0;
			pointer-events: none;
			padding: 19rem 1.7rem 2rem;

			.push & {
				opacity: 1;
				pointer-events: auto;
			}
		}

		.main-nav {
			margin: 0;
			padding: .2rem 0 0;
			list-style: none;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			font-family: $secondary-font-family;
			font-size: 3.6rem;
			line-height: 1;
			gap: 3.6rem;

			@include media-breakpoint-down(md) {
				display: block;
				font-size: 5rem;
				padding: 0;
				line-height: 1.2;
				font-weight: bold;
			}

			> li {
				position: relative;

				@include media-breakpoint-down(md) {
					margin-bottom: 2.5rem;
				}

				> a {
					display: block;
					text-decoration: none;

					@include media-breakpoint-down(md) {
						&::first-letter {
							text-transform: uppercase;
						}
					}
				}

				.opener {
					@include media-breakpoint-up(md) {
						display: none;
					}

					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					z-index: 15;
				}

				&.open {
					.opener {
						display: none;
					}
				}

				&.active {
					> a {
						font-weight: bold;

						@include media-breakpoint-up(md) {
							transform: translate(0, -1px);
						}
					}
				}

				ul {
					@include media-breakpoint-up(md) {
						margin: 0;
						padding: 0;
						list-style: none;
						position: absolute;
						right: 0;
						top: 100%;
						white-space: nowrap;
						font-size: 2.4rem;
						text-align: right;
						transition: all 0.3s ease-out;
						opacity: 0;
						pointer-events: none;
						display: none !important;

						li {
							padding: 1rem 0 0;

							a {
								text-decoration: none;
							}
						}
					}

					@include media-breakpoint-down(md) {
						margin: 0;
						padding: 2.7rem 0 0 2.8rem;
						list-style: none;
						font-size: 2.8rem;
						display: none;
						font-weight: normal;

						li {
							padding: 0 0 2.5rem;
						}
					}
				}

				&:hover {
					> ul {
						//opacity: 1;
						//pointer-events: auto;
					}
				}
			}
		}
	}
}
// btn-menu
.btn-menu {
	--position-center: 1rem;
	width: 2.8rem;
	height: 2.3rem;
	cursor: pointer;
	z-index: 30;
	position: relative;
	padding: 0 !important;
	flex-shrink: 0;
	display: block;
	font-size: 0;
	line-height: 0;
	transition: color 0.3s ease-out;
	margin-left: auto;

	span:before,
	&:before,
	&:after {
		position: absolute;
		content: '';
		left: 0;
		right: 0;
		height: .3rem;
		background: currentColor;
		transition: opacity 0.3s ease-out, top 0.3s ease-out;
	}

	&:after {
		top: 0;
	}

	&:before {
		top: var(--position-center);
	}

	span:before {
		top: 2rem;
	}
}

.push {
	@include media-breakpoint-down(md) {
		overflow: hidden;

		body {
			overflow:hidden;
			height: auto !important;
			//padding-right: var(--scrollbar-width);
		}
	}

	.btn-menu {
		&:before {
			opacity: 0;
		}

		&:after {
			top: var(--position-center);
			animation: anim-btn-menu1 0.3s 0.3s ease forwards;
		}

		span:before {
			top: var(--position-center);
			animation: anim-btn-menu2 0.3s 0.3s ease forwards;
		}
	}
}

@keyframes anim-btn-menu1 {
	100% {
		transform: rotate(40deg);
	}
}

@keyframes anim-btn-menu2 {
	100% {
		transform: rotate(-40deg);
	}
}