@mixin position-side($position: 0) {
	position: absolute;
	top: $position;
	left: $position;
	right: $position;
	bottom: $position;
}

@mixin custom-grid-gutter($gutter-width) {
	margin-left: math.div(-$gutter-width, 2);
	margin-right: math.div(-$gutter-width, 2);

	> [class^='col'] {
		padding-left: math.div($gutter-width, 2);
		padding-right: math.div($gutter-width, 2);
	}
}

@mixin img-cover() {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@mixin underline-hover($height: 1px) {
	text-decoration: none;
	position: relative;
	display: inline-block;
	vertical-align: top;

	&::after {
		position: absolute;
		content: '';
		left: 0;
		bottom: 0;
		right: 100%;
		visibility: hidden;
		border-bottom: $height solid;
		transition: right 0.3s ease-out, visibility 0.3s ease-out;
	}

	&:hover {
		&::after {
			right: 0;
			visibility: visible;
		}
	}
}

@mixin word-break() {
	word-break: break-word;
	word-wrap: break-word;
	hyphens: auto;
}

@mixin custom-underline($custom-underline-color: $body-color, $size: 1px) {
	background-position: 0 100%;
	background-size: ($size*2) ($size*2);
	background-image: linear-gradient(transparent 50%, $custom-underline-color 50%);
	background-repeat: repeat-x;
}

@mixin link-color($color) {
	color: $color;

	&:active,
	&:focus,
	&:hover {
		color: $color;
	}
}

@mixin custom-scroll($color: $primary, $width: .5rem, $radius: 0) {
	overflow-y: scroll;
	overflow-x: hidden;
	scrollbar-color: $color transparent;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: $width;
		border-radius: $radius;
		position: absolute !important;
		right: 0 !important;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
		border-radius: $radius;
	}

	&::-webkit-scrollbar-thumb {
		background: $color;
		border-radius: $radius;
		transition: all 0.3s ease-out;
	}
}

///

@mixin position-over($zIndex: 10) {
	position: absolute;
	z-index: $zIndex;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

@mixin center-image($maxWidth: 100%, $maxHeight: 100%, $zIndex: 10) {
	position: absolute;
	z-index: $zIndex;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	max-width: $maxWidth;
	max-height: $maxHeight;
}

@mixin object-fit-cover() {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@mixin object-fit-contain() {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

@mixin input-position-over() {
	position: absolute;
	z-index: 12;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 0;
	width: 100%;
	height: 100%;
	background: none;
	opacity: 0;
	padding: 0;
	overflow: hidden;
	text-indent: -9999px;
}

@mixin reset-input-number() {
	-moz-appearance: textfield;

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none; 
		margin: 0;
	}
}

@mixin hideTimesFromSearch() {
	&::-ms-clear { display: none; width : 0; height: 0; }
	&::-ms-reveal { display: none; width : 0; height: 0; }
	
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration { display: none; }
}

@mixin blank-text() {
	font-size: 0;
	line-height: 0;
}

// dev 2
// dev 3
// dev 4
// dev 5
