// people-section
.people-section {
	position: relative;
	overflow: hidden;
	padding-top: 13rem;
	padding-bottom: 15rem;

	@include media-breakpoint-down(lg) {
		padding-top: var(--section-padding-y);
		padding-bottom: var(--section-padding-y);
	}
}
