/* details-section */
.details-section {
	padding: var(--gutter) 0;

	@include media-breakpoint-down(md) {
		padding: 4.3rem 0 2rem;
	}

	@include media-breakpoint-up(4xl) {
		padding-top: 10rem;
		padding-bottom: 10rem;
	}

	.title-block {
		padding: 0 0 4.7rem;

		@include media-breakpoint-down(md) {
			padding-bottom: 1.6rem;
		}

		h1, .h1 {
			margin: 0;
			display: flex;
			flex-wrap: wrap;
			gap: 3rem;
			font-size: 3rem;

			@include media-breakpoint-down(md) {
				font-size: 3.2rem;
				gap: 3.2rem;
			}

			small {
				font: inherit;
				font-weight: 300;
			}

			span {
				@include media-breakpoint-down(md) {
					position: relative;

					&::after {
						content: '.';
						position: absolute;
						right: -2rem;
					}
				}
			}
		}
	}

	.text-block {
		font-weight: 300;
		max-width: 94%;

		@include media-breakpoint-down(lg) {
			max-width: none;
		}

		@include media-breakpoint-down(md) {
			font-weight: normal;
		}

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.side-block {
		font-size: 1.4rem;
		//padding-left: 4.5rem;
		padding-left: 16.5%;

		@include media-breakpoint-down(xl) {
			padding-left: 0;
		}

		@include media-breakpoint-down(md) {
			padding-top: 2.5rem;
			font-size: inherit;
		}

		@include media-breakpoint-up(4xl) {
			padding-left: 22%;
		}

		dl {
			margin: 0;
			overflow: hidden;

			@include media-breakpoint-down(md) {
				overflow: visible;
			}

			dt {
				font-weight: bold;
				float: left;
				padding-right: .5rem;

				@include media-breakpoint-down(md) {
					float: none;
					font-weight: normal;
					padding: 0;
				}
			}

			dd {
				margin: 0 0 2.1rem;

				@include media-breakpoint-down(md) {
					font-weight: bold;
					margin-bottom: 2.4rem;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}