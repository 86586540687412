/* projects-section */
.projects-section {
	.holder {
		margin-bottom: calc(var(--gutter)*-1);

		@include media-breakpoint-up(4xl) {
			//margin-bottom: calc(var(--gutter-lg)*-1);
		}

		@include media-breakpoint-down(md) {
			margin-bottom: -4rem;
		}
	}
}
// project-card
.project-card {
	display: block;
	text-decoration: none !important;
	margin: 0 0 var(--gutter);

	@include media-breakpoint-up(4xl) {
		margin-bottom: var(--gutter-lg);
	}

	@include media-breakpoint-down(md) {
		font-size: 2rem;
		margin-bottom: 4rem;
	}

	figure {
		margin: 0;
	}

	.img {
		margin: 0 0 1.8rem;

		@include media-breakpoint-up(4xl) {
			margin-bottom: 2.6rem;
		}

		img, video {
			display: block;
			width: 100%;
			aspect-ratio: 1;
			object-fit: cover;
		}
	}

	h3,  .h3 {
		margin: 0;

		@include media-breakpoint-down(md) {
			font-size: 3.2rem;
			line-height: 1.2;
		}
	}

	p {
		margin: 0;
	}
}