/* lightbox */
.goverlay {
	background: rgba($white, 0.95);
}

.glightbox-mobile .goverlay {
	background: $white;
}

.glightbox-clean {
	--glightbox-btn-size: 3.3rem;

	.gslide-media {
		box-shadow: none;
	}

	.gbtn {
		opacity: 1;
		background: none !important;
		width: var(--glightbox-btn-size);
		height: var(--glightbox-btn-size);

		path {
			fill: currentColor;
		}

		svg {
			width: 1.8rem;
		}
	}

	.gclose {
		right: calc(var(--glightbox-btn-size) + 3rem);
		top: 4rem;

		@include media-breakpoint-down(md) {
			top: 1rem;
			right: 1rem;
		}
	}

	.gnext,
	.gprev {
		top: 50%;
		margin-top: -1.6rem;
		background: url('#{$images}ico-arrow-right.svg') no-repeat 50% 50% !important;
		background-size: 1.1rem auto;

		svg {
			display: none;
		}
	}

	.gprev {
		left: 0;
		transform: scale(-1, 1);
	}

	.gnext {
		right: 0;
	}

	.gcontainer {
		@include media-breakpoint-up(md) {
			padding: 0 calc(var(--glightbox-btn-size) + 3rem);
		}
	}
}

.gslide-image {
	img {
		@include media-breakpoint-up(md) {
			max-height: calc(100svh - 18rem);
		}

		@include media-breakpoint-down(md) {
			max-height: 100svh;
		}
	}
}

.glightbox-container {
	@include media-breakpoint-up(md) {
		display: flex;
		flex-direction: column;

		.gcontainer {
			width: auto;
			margin: 0 auto;
			max-width: 100%;
		}
	}
}