/* footer */
#footer {
	flex-shrink: 0;
	margin-top: auto;
	padding: var(--gutter) 0;
	font-size: 1.2rem;
	line-height: 1.6667;
	text-align: right;

	@include media-breakpoint-down(md) {
		font-size: var(--base-font-size);
		line-height: var(--base-line-height);
	}

	address,
	p {
		margin: 0;
	}

	.social-nav {
		padding: 1.2rem 0 0;

		ul {
			justify-content: flex-end;
		}
	}
}