/* pagination-section */
.pagination-section {
	font-weight: bold;
	font-size: 2.4rem;
	font-family: $secondary-font-family;
	line-height: 1.35;
	flex-shrink: 0;
	margin-top: auto;

	@include media-breakpoint-down(md) {
		display: none;
	}

	&.fz-lg {
		font-size: 2.6rem;
	}
}