// list-table
.list-table {
	width: 100%;
	margin: 0;
	border-collapse: collapse;

	@include media-breakpoint-down(md) {
		min-width: 56rem;
	}

	td {
		border-bottom: 1px solid $gray-150;
		font-size: inherit;
		line-height: 1.25;
		font-family: $Inter;
		padding: 9px 5px;
		vertical-align: middle;

		&:nth-child(1) {
			font-family: $base-font-family;
			padding-left: 0;
			width: 9rem;
		}
	}
}

.table-responsive-sm {
	@include media-breakpoint-down(md) {
		overflow-x: auto;
		overflow-y: hidden;
	}
}