/* gallery-section */
.gallery-section {
	padding: var(--gutter-md) 0 0;

	@include media-breakpoint-up(4xl) {
		padding: var(--gutter) 0 0;
	}

	.holder {
		@include media-breakpoint-down(md) {
			margin-bottom: -4rem;
		}
	}
}
// img-card
.img-card {
	display: block;
	text-decoration: none !important;
	margin: 0 0 var(--gutter);

	@include media-breakpoint-up(4xl) {
		//margin-bottom: var(--gutter-lg);
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 5rem;
	}

	figure {
		margin: 0;

		img, video {
			display: block;
			width: 100%;
			aspect-ratio: 1;
			object-fit: cover;
		}
	}
}

a.img-card {
	&:hover {
		opacity: .7;
	}
}