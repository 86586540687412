// person-card
.person-card {
	margin: 0 0 1.8rem;

	.person-card-image {
		position: relative;
		overflow: hidden;

		&::before {
			content: "";
			display: block;
			width: 100%;
			padding-top: 100%;
		}

		img {
			@include object-fit-cover();
		}
	}

	figcaption {
		font-size: 1.4rem;
		line-height: 1.429;
		padding-top: 2.1rem;

		h3 {
			color: inherit;
			font-size: 1.6rem;
			line-height: 1.2;
			font-weight: 700;
			margin-bottom: 0.2rem;

			a {
				color: inherit;
				text-decoration: none;

				&:hover {
					color: inherit;
					text-decoration: none;
					opacity: $hover-opacity;
				}
			}
		}

		h4 {
			color: inherit;
			font-size: inherit;
			line-height: inherit;
			font-weight: inherit;
			font-family: inherit;
			margin-bottom: 0.9rem;
		}

		p {
			&:last-child {
				margin-bottom: 0;
			}

			a {
				color: inherit;
				text-decoration: none;

				&:hover {
					color: inherit;
					text-decoration: underline;
				}
			}
		}
	}

	@include media-breakpoint-down(lg) {
		margin-bottom: 4rem;

		figcaption {
			font-size: 2rem;
			line-height: 1.45;
			padding-top: 1.6rem;

			h3 {
				font-size: 3.2rem;
				margin-bottom: -0.1rem;
			}

			h4 {
				margin-bottom: 0;
			}
		}
	}
}
