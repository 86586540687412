/* buttons */
.btn {
	--bs-btn-padding-y: 1.4rem;
	--bs-btn-padding-x: 3rem;
	--bs-btn-font-size: 1.8rem;
	--bs-btn-line-height: 2rem;
	--bs-btn-font-weight: 500;
	--bs-btn-border-width: 1px;
	--bs-btn-font-family: #{$font-family-base};
	--bs-btn-border-radius: .4rem;
	--bs-btn-disabled-opacity: 0.5;
	white-space: normal;
	transition: all 0.3s ease-out;
	outline: none;
	text-decoration: none !important;
	box-shadow: none;
	text-align: center;
	position: relative;
	text-align: center;
	display: inline-flex;
	vertical-align: top;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	letter-spacing: 0;

	&-full {
		width: 100%;
	}

	input {
		@include position-side;

		z-index: 20;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		opacity: 0;
		border: 0;
	}

	&-primary {
		//--bs-btn-color: #{$white};
		//--bs-btn-hover-color: #{$white};
		//--bs-btn-active-color: #{$white};

		&:focus {
			//color: var(--bs-btn-color);
		}
	}

	// dev2
	// dev3
	// dev4
}
// btn-volume
.btn-volume {
	position: absolute;
	bottom: var(--gutter);
	right: var(--gutter-md);
	z-index: 15;
	cursor: pointer;

	@include media-breakpoint-down(md) {
		right: 1.7rem;
		bottom: 1.7rem;
	}

	span {
		width: 3.5rem;
		height: 3.5rem;
		display: block;
		background-color: $gray-900;
		background-repeat: no-repeat;
		background-size: 2.1rem;
		background-position: 50% 50%;
	}

	.audio-on {
		display: none;
		background-image: url('#{$images}ico-volume.svg');
	}

	.audio-off {
		display: block;
		background-image: url('#{$images}ico-volume-x.svg');
	}

	&.on .audio-on {
		display: block;
	}

	&.on .audio-off {
		display: none;
	}
}
// dev2
// dev3
// dev4