// awards-section
.awards-section {
	position: relative;
	overflow: hidden;
	padding-top: 22rem;
	padding-bottom: 130px;

	@include media-breakpoint-down(4xl) {
		padding: 6rem 0;
	}

	@include media-breakpoint-down(md) {
		padding: 3rem 0;
	}

	.awards-section-body {
		max-width: 1189px;
	}

	.awards-section-intro {
		padding-bottom: 26px;
		line-height: 1.21;
		font-family: $Inter;
		max-width: 740px;

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.awards-section-group {
		padding-bottom: 13.5rem;

		@include media-breakpoint-down(md) {
			padding-bottom: 6rem;
		}

		&:last-child {
			padding-bottom: 0;
		}

		h2 {
			color: inherit;
			font-size: 24px;
			line-height: 1.2;
			font-weight: 700;
			margin-bottom: 10px;
		}
	}

	.awards-section-link-row {
		padding-top: 12px;
		font-weight: 700;

		a {
			color: inherit;
			text-decoration: none;

			&:hover {
				color: inherit;
				text-decoration: underline;
			}
		}
	}
}
