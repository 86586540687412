// info-section
.info-section {
	position: relative;
	overflow: hidden;
	padding-top: 9.8rem;
	padding-bottom: 9.8rem;
	line-height: 1.474;

	p {
		margin-bottom: 2.4rem;
	}

	h2 {
		font-size: inherit;
		line-height: inherit;
		font-weight: 700;
		font-family: inherit;
		margin-bottom: 0;
	}

	@include media-breakpoint-up(xxl) {
		.container-fluid {
			> .row {
				> div[class^="col-"] {
					&:nth-child(1) {
						width: 46%;
					}

					&:nth-child(2) {
						width: 19.9%;
					}

					&:nth-child(3) {
						width: 20.2%;
					}
				}
			}
		}
	}

	@include media-breakpoint-down(lg) {
		padding-top: var(--section-padding-y);
		padding-bottom: var(--section-padding-y);
	}
}
