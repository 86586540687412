/* team-single-section */
.team-single-section {
	padding: 3rem 0 13rem;

	@include media-breakpoint-down(md) {
		padding: 0;
	}

	.row {
		--bs-gutter-x: 3rem;
	}

	.photo {
		margin: 0;

		@include media-breakpoint-down(md) {
			margin: 0 0 3rem;
		}

		img {
			display: block;
			width: 100%;
		}
	}

	.descr {
		padding: 2.7rem 0 0 21%;
		flex-shrink: 0;
		width: 100%;
		display: flex;
		flex-direction: column;

		@include media-breakpoint-down(xl) {
			padding-left: 0;
			padding-top: 0;
		}

		h2, .h2 {
			margin-bottom: 5.7rem;

			@include media-breakpoint-down(xl) {
				margin-bottom: 2rem;
			}
		}

		p {
			margin-bottom: 2.1rem;
		}

		ul:not([class]) {
			margin: 0 0 #{$base-line-height}em;
			padding: 0;
			list-style: none;

			&:last-child {
				margin-bottom: 0;
			}

			li {
				padding: 0 0 .5rem;

				&:last-child {
					padding-bottom: 0;
				}
			}
		}

		footer {
			flex-shrink: 0;
			margin-top: auto;
			margin-bottom: -.5rem;
		}
	}
}