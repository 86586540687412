/* hero-section */
.hero-section {
	margin: 0;

	figure {
		margin: 0;
		height: 100svh;
		position: relative;

		img, video {
			@include img-cover;
			object-position: 50% 0;
		}
	}

	.swiper-pagination {
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 15;
		pointer-events: none;
		padding: calc(var(--gutter)*.5);
		display: flex;
		justify-content: center;
		align-items: center;
		gap: calc(var(--gutter)*.5);
		color: $white;

		@include media-breakpoint-down(md) {
			display: none;
		}

		.swiper-pagination-bullet {
			flex-shrink: 0;
			width: 1.3rem;
			height: 1.3rem;
			border-radius: 50%;
			border: 1px solid;
			transition: all 0.3s ease-out;
			cursor: pointer;
			pointer-events: auto;

			&-active {
				background: currentColor;
			}
		}
	}

	&.mobile-type2 {
		@include media-breakpoint-down(md) {
			margin-top: 15rem;

			figure {
				height: auto;

				img, video {
					position: static;
					display: block;
					width: 100%;
				}
			}
		}
	}
}