/* img-txt-section */
.img-txt-section {
	padding: var(--gutter-lg) 0;

	@include media-breakpoint-down(4xl) {
		padding: var(--gutter) 0;
	}

	@include media-breakpoint-down(md) {
		padding: var(--gutter-md) 0;
		font-size: 2rem;
	}

	.awards-section + & {
		@include media-breakpoint-up(4xl) {
			padding-top: 20rem;
		}
	}

	.img {
		margin: 0;

		@include media-breakpoint-down(lg) {
			margin: 0 0 var(--gutter);
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 2rem;
		}

		img {
			display: block;
			width: 100%;
			object-fit: cover;
			aspect-ratio: 982/1111;
		}
	}

	.txt {
		padding-top: 9.2rem;

		@include media-breakpoint-down(4xl) {
			padding-top: 6rem;
		}

		@include media-breakpoint-down(xl) {
			padding-top: 0;
		}

		h2, .h2 {
			margin-bottom: 4.5rem;

			@include media-breakpoint-down(4xl) {
				margin-bottom: 3rem;
			}

			@include media-breakpoint-down(md) {
				margin-bottom: 1rem;
			}
		}

		ul:not([class]) {
			margin: 0 0 #{$base-line-height}em;
			padding: 0;
			list-style: none;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.logos {
			margin: 0;
			padding: 4rem 0 0;
			list-style: none;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			figure {
				margin: 0;
			}

			img {
				display: block;
				max-height: 10.5rem;
			}

			> li {
				max-width: 50%;
				flex-shrink: 0;
			}
		}
	}

	&.reverse {
		.row-holder {
			@include media-breakpoint-up(lg) {
				flex-direction: row-reverse;

				.offset-xl-1 {
					margin-right: auto;
				}
			}
		}
	}
}