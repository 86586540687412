/* publications-section */
.publications-section {
	padding: var(--gutter-lg) 0;

	@include media-breakpoint-up(4xl) {
		padding: 23.5rem 0 14rem;
	}

	@include media-breakpoint-down(md) {
		padding: var(--gutter) 0 0;
	}

	.title-block {
		padding: 0 0 8rem;

		@include media-breakpoint-down(md) {
			padding-bottom: 3rem;
		}

		h2, .h2 {
			margin-bottom: 1.5rem;
		}

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.btn-load-more-items {
		margin-top: -7.5rem;
		padding-bottom: 6.4rem;
		font-family: $secondary-font-family;

		@include media-breakpoint-down(4xl) {
			margin-top: -4rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 2.6rem;
			margin-top: -2rem;
		}
	}

	.list-block {
		h2, .h2 {
			font-size: 3rem;
			margin-bottom: 1.3rem;
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			columns: 4;
			column-gap: 0;

			@include media-breakpoint-down(xl) {
				columns: 2;
			}

			@include media-breakpoint-down(md) {
				columns: 1;
			}

			li {
				border-bottom: 1px solid $gray-200;
				padding: .5rem 0;
				break-inside: avoid;
				padding-right: 2rem;

				strong {
					&::after {
						content: '|';
						font-weight: normal;
						padding: 0 .5rem;
					}
				}
			}
		}

		.btn-load-more-items {
			margin-top: 0;
			padding-top: 2rem;
			padding-bottom: 1.5rem;
		}
	}
}
// post-card
.post-card {
	display: block;
	text-decoration: none !important;
	margin: 0 0 var(--gutter);
	font-size: 1.4rem;

	@include media-breakpoint-up(4xl) {
		margin-bottom: var(--gutter-lg);
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 5rem;
	}

	figure {
		margin: 0;
	}

	.img {
		margin-bottom: 1.5rem;

		@include media-breakpoint-down(md) {
			margin-bottom: 1rem;
		}

		img, video {
			display: block;
			width: 100%;
			aspect-ratio: 565/662;
			object-fit: cover;
		}
	}

	h3,  .h3 {
		margin: 0;
		font-size: 3.6rem;
		line-height: 1.1;

		@include media-breakpoint-down(xxl) {
			font-size: 3.2rem;
		}

		@include media-breakpoint-down(md) {
			font: inherit;
		}
	}

	p {
		margin: 0;
	}

	figcaption {
		@include media-breakpoint-down(md) {
			display: flex;
			justify-content: flex-end;
			text-align: right;
			flex-wrap: wrap;
			font-weight: bold;

			p {
				&::before {
					content: '|';
					padding: 0 .7rem;
				}
			}
		}
	}
}