// vars
$social-font-path: '../fonts/';
$fonts: '../fonts/';
$images: '../images/';
$fa-font-path: '../fonts/fontawesome/';

// bootstrap vars
$grid-breakpoints: (
	xs: 0,
	sm: 390px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1600px,
	3xl: 1920px,
	4xl: 2560px,
	5xl: 3840px,
);

//$container-max-widths: (
	//sm: 730px,
	//md: 53.64rem,
	//lg: 130rem,
	//xl: 130rem,
	//xxl: 133rem,
//);

$grid-columns: 12;
//$grid-gutter-width: 3rem;
$bootstrap-sass-asset-helper: false;
$icon-font-path: if($bootstrap-sass-asset-helper, 'bootstrap/', '../fonts/');

// fonts
$Circe: "Circe", Arial, sans-serif;
$Inter: "Inter", Arial, sans-serif;
$AvantGardeBkBT: "AvantGarde Bk BT", Arial, sans-serif;
$base-font-family: $Circe;
$secondary-font-family: $AvantGardeBkBT;
$base-font-size: 1.6rem;
$base-line-height: 1.5;
$base-font-weight: 400;
$base-letter-spacing: 0;
$heading-font-family: $base-font-family;

// opacity
$hover-opacity: 0.7;

// global colors
$white: #fff;
$black: #000;

$gray-100: #fdfdfd;
$gray-200: #f7f7f7;
$gray-300: #e1e1e1 !default;
$gray-400: #d6d6d6 !default;
$gray-500: #777777 !default;
$gray-600: #555555 !default;
$gray-700: #333333 !default;
$gray-800: #2c2d2d !default;
$gray-900: #050505;

$indigo: #1a405b !default;
$purple: #d2bdf9 !default;
$pink: #ff9190 !default;
$red: #e43c2f !default;
$orange: #e87722 !default;
$yellow: #fff9c4 !default;
$green: #06a00e !default;
$cyan: #49bfab !default;
$teal: #64b6c3 !default;
$blue: #17469e !default;
$beige: #fff3ea !default;
$brown: #4f321e !default;

$primary: $red !default;
$secondary: $blue !default;
$success: $green !default;
$info: $indigo !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $white !default;
$dark: $black !default;

// colors
$body-bg: $white;
$base-color: $black;
$heading-color: inherit;

// transition vars
$transition: all 0.3s ease;

// dev2
// section padding vars
$section-padding-y: 12rem;
$section-padding-y-md: 4rem;
$section-padding-y-sm: 3rem;

$gray-150: #f7f7f7;

// dev3
// dev4
// dev5
