.social-nav {
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		align-items: center;
		gap: 1.5rem;
		font-size: 1.8rem;
		line-height: 1;

		@include media-breakpoint-down(md) {
			font-size: 3rem;
		}

		li {
			a {
				text-decoration: none;
				display: block;

				&:hover {
					opacity: .7;
				}
			}
		}
	}
}

.link-more {
	font-weight: bold;
}

.border-list {
	margin: 0 0 2.4rem;
	padding: 0;
	list-style: none;

	> li {
		border-bottom: 1px solid $gray-200;
		padding: 1.5rem 0 .4rem;
	}
}

.load-more-items {
	&:not(.mobile) {
		.item {
			transition: opacity 0.3s ease-out;
	
			&.hidden {
				position: absolute;
				left: -9999px;
				//transform: scale(0);
				opacity: 0;
				pointer-events: none;
			}
		}
	}

	&.mobile {
		.item {
			@include media-breakpoint-down(md) {
				transition: opacity 0.3s ease-out;
	
				&.hidden {
					position: absolute;
					left: -9999px;
					//transform: scale(0);
					opacity: 0;
					pointer-events: none;
				}
			}
		}
	}
}
// dev2
// dev3
// dev4