/* filter-section */
.filter-section {
	padding: 1.9rem 0;
	position: sticky;
	top: var(--header-height);
	z-index: 100;
	background: $body-bg;
	transition: all 0.3s ease-out;

	&::before {
		content: '';
		@include position-side;
		z-index: -1;
		background: $body-bg;
		transform: translate(0, -100%);
	}

	.scrolled-header.scroll-down & {
		top: 0;
	}

	@include media-breakpoint-down(md) {
		padding: 1.8rem 0;

		&.d-none.d-md-block {
			& + * {
				margin-top: 1.8rem;
			}
		}
	}

	nav {
		@include media-breakpoint-down(md) {
			position: relative;
		}

		.btn-select-filter {
			display: block;
			border: 1px solid;
			position: relative;
			font-size: 2rem;
			text-transform: uppercase;
			text-decoration: none;
			background: $gray-100;
			line-height: 2.5rem;
			padding: 1rem 5rem 1rem 1.8rem;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;

			&::after {
				position: absolute;
				content: '';
				width: 2.2rem;
				height: 1.2rem;
				right: 1.7rem;
				top: 50%;
				background: url('#{$images}ico-arrow-down.svg') no-repeat;
				background-size: contain;
				transform: translate(0, -50%);
				transition: transform 0.3s ease-out;
			}
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-end;
			gap: 1rem var(--gutter-md);
			text-transform: uppercase;

			@include media-breakpoint-down(md) {
				position: absolute;
				left: 0;
				top: 100%;
				right: 0;
				background: $gray-100;
				border: 1px solid;
				border-width: 0 1px 1px;
				display: block;
				z-index: 30;
				transition: all 0.3s ease-out;
				opacity: 0;
				pointer-events: none;
			}

			li {
				&.active {
					font-weight: 700;

					@include media-breakpoint-down(md) {
						display: none;
					}
				}

				> a {
					@include media-breakpoint-down(md) {
						display: block;
						text-decoration: none;
						padding: 1rem 1.8rem;
					}
				}
			}
		}
	}

	&.open-filter nav {
		ul {
			opacity: 1;
			pointer-events: auto;
		}

		.btn-select-filter {
			&::after {
				transform: translate(0, -50%) scale(1, -1);
			}
		}
	}
}
// filter-container
.filter-container {
	position: relative;

	.item {
		transition-duration: .3s;
		transition-property: opacity, transform;
		position: relative;
		z-index: 5;

		&.hidden {
			opacity: 0;
			position: absolute;
			pointer-events: none;
			z-index: 1;
			transform: scale(0);
		}
	}
}