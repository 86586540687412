/* General Reset */
:root {
	--base-font-size: #{$base-font-size};
	--base-line-height: #{$base-line-height};
	--gutter: 6rem;
	--gutter-md: calc(var(--gutter)*.5);
	--gutter-lg: calc(var(--gutter)*1.5);
	// dev2
	--section-padding-y: #{$section-padding-y};

	@include media-breakpoint-down(lg) {
		--section-padding-y: #{$section-padding-y-md};
	}

	@include media-breakpoint-down(md) {
		--section-padding-y: #{$section-padding-y-sm};
	}

	// dev3
	// dev4
	// dev5
}

html {
	height: 100%;
	font-size: 10px;
}

body {
	margin: 0;
	font-size: var(--base-font-size);
	line-height: var(--base-line-height);
	font-family: $base-font-family;
	font-weight: $base-font-weight;
	letter-spacing: $base-letter-spacing;
	color: $base-color;
	background: $body-bg;
	min-width: 320px;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

a {
	transition: all 0.3s ease-out;
	text-decoration: none;
	color: inherit;

	&:hover {
		text-decoration: underline;
	}
}

a:active,
a:focus,
a:hover {
	outline: none;
	color: inherit;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
	-webkit-appearance: none;
	cursor: pointer;
	transition: all 0.3s ease-out;
}

p {
	margin: 0 0 #{$base-line-height}em;
}

/* General settings */
main {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.container-fluid {
	--bs-gutter-x: var(--gutter);

	@include media-breakpoint-up(4xl) {
		--bs-gutter-x: calc(var(--gutter)*2);
	}

	@include media-breakpoint-down(md) {
		--bs-gutter-x: 3.4rem;
	}

	// dev 2
	&.container-fluid-small {
		@include media-breakpoint-up(xxl) {
			max-width: 84%;
		}
	}
}

.row {
	--bs-gutter-x: var(--gutter);

	@include media-breakpoint-down(md) {
		--bs-gutter-x: var(--gutter-md);
	}
}

.h1,
h1 {
	font: 700 4.2rem/1.4 $heading-font-family;
	margin: 0 0 2rem;
	color: $heading-color;
}

.h2,
h2 {
	font: 700 3.2rem/1.46 $heading-font-family;
	margin: 0 0 2rem;
	color: $heading-color;
}

.h3,
h3 {
	font: 700 2.6rem/1.35 $heading-font-family;
	margin: 0 0 2rem;
	color: $heading-color;
}

.h4,
h4 {
	font: 700 2.4rem/1.35 $base-font-family;
	margin: 0 0 2rem;
	color: $heading-color;
}

.h5,
h5 {
	font: 700 2rem/1.3 $base-font-family;
	margin: 0 0 2rem;
	color: $heading-color;
	display: block;
}

.h6,
h6 {
	font: 700 1.6rem/1.5 $base-font-family;
	margin: 0 0 2rem;
	color: $heading-color;
	display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	word-wrap: break-word;
}

strong {
	font-weight: 700;
}

.tdu {
	text-decoration: underline;
}

.ttu {
	text-transform: uppercase;
}

img {
	max-width: 100%;
	height: auto;

	&.lazyload {
		opacity: 0;
		transition: opacity 0.3s;
	}

	&.lazyloaded {
		opacity: 1;
	}
}

// dev2
// dev3
// dev4
// dev5
