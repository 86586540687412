// hero-banner
.hero-banner {
	margin: 0;
	position: relative;
	overflow: hidden;

	.hero-banner-image {
		position: relative;
		overflow: hidden;

		img {
			display: block;
			width: 100%;

			@include img-fluid();
		}
	}
}
